import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: "/home",
    hidden: true,
  },
  {
    path: '/home',    
    component: () => import("@/views/home/index"),
    meta:{"title":''}
  },
  {
    path: '/AdvicePage',
    name: 'AdvicePage',
    component: () => import("@/views/AdvicePage/index"),
    meta:{"title":'我要提意见'}
  },
  {
    path: '/LeaderPage',
    name: 'LeaderPage',
    component: () => import("@/views/LeaderPage/index"),
    meta:{"title":'我要找领导'}
  },
  {
    path: '/ThumbPage',
    name: 'ThumbPage',
    component: () => import("@/views/ThumbPage/index"),
    meta:{"title":'我要点个赞'}
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router