<!--
 * @Descripttion: 
 * @version: 1.1
 * @Author: @qing
 * @Date: 2022-06-30 15:24:59
 * @LastEditors: @qing
 * @LastEditTime: 2022-10-11 14:49:16
-->
<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- <ShopList/> -->
  </div>
</template>

<script>
// import ShopList from '@/views/shopManagement/shopList';

export default {
  name: 'App',
  components: {
    // ShopList
  }
}
</script>

<style>
</style>
