import Vue from 'vue'
import App from './App.vue'
import 'lib-flexible/flexible.js'
import "@/style/index.scss"
import router from './router'

Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
